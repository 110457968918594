import React from "react";
import { FaPhone, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { pageIDs } from "../../data/ID";

const ContactSection = () => {
  return (
    <section className="bg-gray-100 p-4 min-h-[80vh]" id={pageIDs.contactUs}>
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 text-center mt-3">
        Contact Us
      </h1>
      <div className="container mb-24 mt-20 mx-auto md:px-6">
        <section className="mb-32">
          <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="flex flex-wrap items-center">
              <div className="block w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                <div className="h-[500px] w-full">
                  <iframe
                    title="Google Maps Location: Bala Car Travels, Rajahmundry"
                    className="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.5756544399665!2d81.77205430000001!3d16.995410900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a37a5a16cbf2a29%3A0xd493fbd96f06d870!2sBala%20car%20travels!5e0!3m2!1sen!2sin!4v1695105792484!5m2!1sen!2sin"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                <div className="flex flex-wrap px-3 pt-12 pb-12 md:pb-0 lg:pt-0">
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="w-10 h-10 rounded-full bg-indigo-100 text-blue-600 flex items-center justify-center">
                        <FaPhone className="w-5 h-5" />
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Contact Us
                        </p>

                        <p className="text-neutral-500 dark:text-neutral-200">
                          +91 770-204-8444
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className=" w-auto h-auto rounded-full bg-indigo-100 text-blue-600 flex items-center justify-center">
                        <FaMapMarkerAlt className="w-5 h-5" />
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark-text-white">
                          Address
                        </p>
                        <p className="text-neutral-500 dark-text-neutral-200">
                          6-2-14, Tyagaraja Nagar, Rajamahendravaram, Andhra
                          Pradesh 533101
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="w-auto h-auto rounded-full bg-indigo-100 text-blue-600 flex items-center justify-center">
                        <FaClock className="w-5 h-5" />
                      </div>

                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark-text-white">
                          Opening Hours
                        </p>
                        <div className="flex flex-col pr-5">
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Monday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Tuesday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Wednesday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Thursday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Friday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Saturday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p className="text-neutral-500 dark-text-neutral-200">
                              Sunday:
                            </p>
                            <p className="text-neutral-500 dark-text-neutral-200">
                              24 hours
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default ContactSection;
