import React, { useState } from "react";
import InnovaCrystaImage from "../../images/Innova.png"; // Import the image
import Wave from "../../images/WaveT.svg";
import { calltoThisNumber, pageIDs } from "../../data/ID";
import TypeWriter from "../../components/typewriter/TypeWriter";

const HomeSection = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const sectionStyle = {
    backgroundImage: `url(${Wave})`, // Set the star image as the background
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <section
      className="min-h-[80vh] bg-white flex flex-col items-center p-3 pt-16"
      style={sectionStyle}
      id={pageIDs.home}
    >
      <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-7xl mt-3 md:mt-5 lg:mt-7 font-bold">
        <span className="animated-text">Travel Agency in Rajahmundry</span>
      </h1>
      {!isImageLoaded && (
        <div className="w-full h-48 white animate-pulse"></div>
      )}
      <img
        src={InnovaCrystaImage}
        alt="car"
        className="w-full md:w-2/3 lg:w-1/2 xl:w-2/5 mt-8 md:mt-6 lg:mt-8 max-w-xl"
        style={
          isImageLoaded ? { display: "inline-block" } : { display: "none" }
        }
        onLoad={() => setIsImageLoaded(true)}
      />
      <div className="text-gray-800 text-xl  font-bold">
        <TypeWriter/>
      </div>
      <div className="pb-4 my-9 sm:mb-2">
        <a href={calltoThisNumber}>
          <button className="bg-white hover:bg-blue-800 text-blue-600 font-bold py-2 px-4 rounded-md flex items-center">
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.970c-.135.100-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.970-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.820V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="ml-3">Get Quote</span>
          </button>
        </a>
      </div>
    </section>
  );
};

export default HomeSection;
