import InnovaCrystaImage from "../images/Innova.png"; // Import the image

import DzireWhite from "../images/fleet/DzireWhite.webp"

import ForceVan17 from "../images/fleet/ForceVan17.png"
import ForceVan12 from "../images/fleet/ForceVan12.png"
import Etios from "../images/fleet/Etios.png"
import Etriga from "../images/fleet/Ertiga.webp"

export interface VehicleI {
  vehicleName: string;
  image?: string;
  minimumKilometers: number;
  costPerKilometer: number;
  driverBeta:number
}

export const vehicleData: VehicleI[] = [
  {
    vehicleName: "Swift Dzire",
    image: DzireWhite,
    minimumKilometers: 200,
    costPerKilometer: 14,
    driverBeta:200
  },
  {
    vehicleName: "Toyota Etios",
    image: Etios,
    minimumKilometers: 200,
    costPerKilometer: 14,
    driverBeta:200

  },
  {
    vehicleName: "Maruti Suzuki Ertiga",
    image: Etriga,
    minimumKilometers: 300,
    costPerKilometer: 17,
    driverBeta:300
  },
  {
    vehicleName: "Toyota Innova",
    image: InnovaCrystaImage,
    minimumKilometers: 300,
    costPerKilometer: 17,
    driverBeta:300
  },
  {
    vehicleName: "Innova Crysta",
    image: InnovaCrystaImage,
    minimumKilometers: 300,
    costPerKilometer: 20,
    driverBeta:300
  },
  {
    vehicleName: "Tempo 12 Seater",
    image: ForceVan12,
    minimumKilometers: 300,
    costPerKilometer: 25,
    driverBeta:500
  },
  {
    vehicleName: "Tempo 17 Seater",
    image: ForceVan17,
    minimumKilometers: 300,
    costPerKilometer: 32,
    driverBeta:500
  },
];
