import React from "react";

import Innova from "../../images/Innova.png";

import StarBackground from "../../images/Star.svg";
import { pageIDs } from "../../data/ID";

const AboutSection = () => {
  const sectionStyle = {
    backgroundImage: `url(${StarBackground})`, // Set the star image as the background
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <section className=" p-4 pb-20" style={sectionStyle} id={pageIDs.about}>
      <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 text-center">
        About Us
      </h2>
      <section className="text-gray-600 body-font mt-10">
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded "
              alt="hero"
              src={Innova}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Your Journey, Our Passion
            </h1>
            <p className="mb-8 leading-relaxed">
              Bala Car Travels is a trusted name in the car rentals and
              transportation services. With a commitment to providing top-notch
              service, we have been serving our valued customers for over a
              decade.
            </p>
            <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-2">
              Why Choose Us?
            </h3>
            <p className="text-gray-700">
              - Fleet of modern and well-maintained vehicles
              <br />
              - Professional and courteous drivers
              <br />
              - Competitive pricing and transparent billing
              <br />
              - 24/7 customer support
              <br />- Convenient booking options
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AboutSection;
