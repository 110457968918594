import React, { useState } from "react";
import { VehicleI } from "../../data/vehicleData";
import { calltoThisNumber } from "../../data/ID";

const UICard: React.FC<VehicleI> = ({
  vehicleName,
  image,
  minimumKilometers,
  costPerKilometer,
  driverBeta,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div className="max-w-sm bg-white hover:shadow-none hover:shadow-black h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden w-96">
      <div className="group p-6 flex items-center">
        {" "}
        {/* Use flex and items-center to center the image */}
        {!isImageLoaded && (
          <div className="w-full h-full bg-gray-300 animate-pulse"></div>
        )}
        {image && (
          <img
            className={`w-auto h-auto object-center transform scale-100 group-hover:scale-105 transition-transform ${
              isImageLoaded ? "block" : "hidden"
            }`}
            src={image}
            alt={vehicleName}
            onLoad={() => setIsImageLoaded(true)}
          />
        )}
      </div>

      <div className="px-6 py-4">
        <div className="font-bold text-2xl mb-2 text-gray-900  ">
          {vehicleName}
        </div>

        <div className="pt-4 pb-2 title-font text-lg font-medium text-gray-900">
          <div className="">{`Minimum kilometers : ${minimumKilometers}`}</div>
          <div className="">{`Cost per kilometer : ₹${costPerKilometer}`}</div>
          <div className="">{`Driver beta : ₹${driverBeta}`}</div>
        </div>
      </div>

      <div className="px-6 pb-4">
        <a href={calltoThisNumber}>
          <button className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-md flex items-center">
            <span className="mr-2">
              {/* You can add your phone icon here */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.970c-.135.100-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.970-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.820V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="ml-3">Book Now</span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default UICard;
