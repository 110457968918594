import React from "react";
import { Typewriter } from "react-simple-typewriter";

const TypeWriter = () => {
  return (
    <div>
      <h3 style={{ paddingTop: "5rem", margin: "auto 0" }} className="flex">
        <div className="text-transparent">{"."}</div>

        <span style={{ color: "black", fontWeight: "bold" }}>
          {/* Style will be inherited from the parent element */}
          <Typewriter
            words={["Airport Services", "Outstation Travel", "Local Events"]}
            loop={false}
            cursor
            cursorStyle=""
            typeSpeed={100}
            deleteSpeed={100}
            delaySpeed={1000}
          />
        </span>
      </h3>
    </div>
  );
};

export default TypeWriter;
