import React from "react";
import "./App.css";
import HomeSection from "./sections/home/HomeSection";
import PricingSection from "./sections/pricing/PricingSection";
import AboutSection from "./sections/about/AboutSection";
import ContactSection from "./sections/contact/ContactSection";
import HeaderSection from "./sections/header/HeaderSection";
import ServicesSection from "./sections/services/ServicesSection";
import Testimonial from "./sections/testimonial/Testimonial";

import FooterSection from "./sections/footer/FooterSection";

function App() {
  return (
    <div className="relative">
      <HeaderSection />
      <HomeSection />
      <PricingSection />
      <AboutSection />
      <ServicesSection />
      <Testimonial />
      <ContactSection />
      <FooterSection />
    </div>
  );
}

export default App;
