import React from "react";
import { vehicleData } from "../../data/vehicleData";
import UICard from "../../components/card/UICard";
import { pageIDs } from "../../data/ID";

const PricingSection = () => {
  return (
    <section
      className="bg-gray-100 p-4 min-h-[80vh] pb-8 m-auto "
      id={pageIDs.services}
    >
      <h2 className="text-2xl md:text-4xl lg:text-4xl text-center my-9 font-bold">
        Vehicle Fleet & Pricing
      </h2>
      {/* Add your car fleet information here */}

      <div className="flex flex-wrap justify-center gap-5">
        {vehicleData.map((vehicle, index) => {
          return (
            <UICard
              key={index}
              vehicleName={vehicle.vehicleName}
              image={vehicle.image}
              minimumKilometers={vehicle.minimumKilometers}
              costPerKilometer={vehicle.costPerKilometer}
              driverBeta={vehicle.driverBeta}
            />
          );
        })}
      </div>
    </section>
  );
};

export default PricingSection;
