import React from "react";
import { pageIDs } from "../../data/ID";

const HeaderSection = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scrolling smooth
    });
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-blue-600 shadow-md p-4 z-50">
      <div className="container mx-auto h-full flex justify-between items-center">
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl text-white font-bold cursor-pointer"
        onClick={scrollToTop}
        >
          Bala Car Travels
        </h1>

        <nav className="hidden lg:flex lg:space-x-6 text-white">
          <ul className="lg:flex space-x-6 text-white">
            <li>
              <a
                href={"#" + pageIDs.home}
                className="hover:text-indigo-500 transition duration-300"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href={"#" + pageIDs.services}
                className="hover:text-indigo-500 transition duration-300"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href={"#" + pageIDs.about}
                className="hover:text-indigo-500 transition duration-300"
              >
                About
              </a>
            </li>
            <li>
              <a
                href={"#" + pageIDs.contactUs}
                className="hover:text-indigo-500 transition duration-300"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default HeaderSection;
