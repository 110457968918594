import React from "react";

const FooterSection = () => {
  return (
    <footer className="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left">
      <div className="p-4 text-center text-neutral-700 dark:text-neutral-200">
        Bala Car Travels
      </div>
    </footer>
  );
};

export default FooterSection;
