import React from "react";
import { MdLocalAirport } from "react-icons/md";
import { FaCity } from "react-icons/fa";

import { GiMountainRoad } from "react-icons/gi";

const ServicesSection = () => {
  return (
    <section className="text-gray-600 body-font bg-gray-100">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Our Services
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Enjoy hassle-free travel with Bala Car Travels. From local events to
            airport pickups and memorable outstation trips.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-blue-600 inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-blue-600 mb-5 flex-shrink-0">
              <FaCity className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Local
              </h2>
              <p className="leading-relaxed text-base">
                Arrive at local events and special occasions with ease. We offer
                car rental services for stress-free transportation, making your
                events memorable and convenient.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-blue-600 mb-5 flex-shrink-0">
              <MdLocalAirport className="w-10 h-10" />
            </div>

            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Airport
              </h2>
              <p className="leading-relaxed text-base">
                For quick and reliable airport pickup and drop-off services,
                choose us. We'll ensure you reach your destination on time and
                stress-free.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-blue-600 mb-5 flex-shrink-0">
              <GiMountainRoad className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Outstation
              </h2>
              <p className="leading-relaxed text-base">
                Explore new horizons with our outstation car rentals. Whether
                it's a weekend getaway or an extended journey, we've got you
                covered.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
